import {
  Box,
  HStack,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react"
import { EntityId, FulfillmentTypes } from "@jackfruit/common"
import React from "react"
import { useTranslation } from "react-i18next"
import { useCart } from "~/hooks/useCart"
import { usePrintService } from "~/hooks/usePrintService"

interface TaxProps {
  taxInclusive: boolean
  taxTotalFloat: number
  taxTotalHuman: string
  fulfillment?: FulfillmentTypes
  printServiceId?: EntityId
}

const TaxRow: React.FC<TaxProps> = ({
  taxInclusive,
  taxTotalFloat,
  taxTotalHuman,
  fulfillment,
  printServiceId,
}) => {
  const { t } = useTranslation()
  const { cart } = useCart()
  const { fulfillment: cartFulfillment } = cart
  const { printService } = usePrintService(printServiceId ?? 0)

  const currentFulfillment = fulfillment ?? cartFulfillment

  const hideTaxLine =
    !taxTotalFloat || !printService?.displayTax || !taxInclusive

  if (hideTaxLine) {
    return <Tr />
  }

  return (
    <Tr>
      <Td paddingX={0} paddingY={2}>
        <HStack spacing={1} alignItems="baseline">
          <Text fontWeight="bold">
            {t("components.checkout.OrderSummaryTotals.TaxRow.Tax")}
          </Text>
          {taxInclusive && (
            <Text fontSize={10}>
              {currentFulfillment === "pickup" &&
                t(
                  "components.checkout.OrderSummaryTotals.TaxRow.CalculatedInStore"
                )}
            </Text>
          )}
        </HStack>
      </Td>
      <Td paddingX={0} paddingY={2}>
        <Text textAlign="right">{taxTotalHuman}</Text>
      </Td>
    </Tr>
  )
}

export const SummaryTotals = () => {}

interface OrderSummaryProps {
  freightFloat: number
  freightHuman: string
  taxInclusive: boolean
  taxTotalFloat: number
  taxTotalHuman: string
  totalHuman: string
  discountFloat: number
  discountHuman: string
  printServiceId?: EntityId
  couponCode?: string
  fulfillment?: FulfillmentTypes
  isUpdating?: boolean
  isPendingAddress?: boolean
}

const OrderSummaryTotals: React.FC<OrderSummaryProps> = ({
  freightFloat,
  freightHuman,
  taxInclusive,
  taxTotalFloat,
  taxTotalHuman,
  totalHuman,
  couponCode,
  discountFloat,
  discountHuman,
  fulfillment,
  printServiceId,
  isUpdating = false,
  isPendingAddress = false,
}) => {
  const { t } = useTranslation()

  const showCouponCodeRow = Boolean(couponCode) && Boolean(discountFloat)

  const couponCodeRow = (
    <Tr>
      <Td paddingX={0} paddingY={2}>
        <HStack spacing={1} alignItems="baseline">
          <Text fontWeight="bold">
            {t("components.checkout.OrderSummaryTotals.Discount")}
          </Text>
          <Text fontSize={10}>{couponCode}</Text>
        </HStack>
      </Td>
      <Td paddingX={0} paddingY={2}>
        <Text textAlign="right" color="red.500">
          -{discountHuman}
        </Text>
      </Td>
    </Tr>
  )

  return (
    <Box mt={4}>
      <Table size="md" colorScheme="whiteAlpha" mt={-2}>
        <Tbody>
          {Boolean(freightFloat) && (
            <Tr>
              <Td paddingX={0} paddingY={2}>
                <HStack spacing={1} alignItems="baseline">
                  <Text fontWeight="bold">
                    {t("components.checkout.OrderSummaryTotals.Shipping")}
                  </Text>
                  {isPendingAddress && (
                    <Text fontSize={10}>
                      {t(
                        "components.checkout.OrderSummaryTotals.ShippingPending"
                      )}
                    </Text>
                  )}
                </HStack>
              </Td>
              <Td paddingX={0} paddingY={2}>
                <Text textAlign="right">{freightHuman}</Text>
              </Td>
            </Tr>
          )}

          <TaxRow
            taxInclusive={taxInclusive}
            taxTotalFloat={taxTotalFloat}
            taxTotalHuman={taxTotalHuman}
            fulfillment={fulfillment}
            printServiceId={printServiceId}
          />
          {showCouponCodeRow && couponCodeRow}
          <Tr borderBottomWidth={1} borderBottomColor="gray.200">
            <Td paddingX={0} paddingY={2}>
              <HStack spacing={1} align="baseline">
                <Text fontWeight="bold">
                  {t("components.checkout.OrderSummaryTotals.Total")}
                </Text>
                {taxInclusive && (
                  <Text fontSize={10}>
                    {t("components.checkout.OrderSummaryTotals.TaxInclusive")}
                  </Text>
                )}
              </HStack>
            </Td>
            <Td paddingX={0} paddingY={2} textAlign="right">
              {isUpdating && <Spinner size="sm" color="primary.400" />}
              {!isUpdating && <Text fontWeight="bold">{totalHuman}</Text>}
            </Td>
          </Tr>
        </Tbody>
      </Table>
    </Box>
  )
}

export default OrderSummaryTotals
